import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export default function Mouse({ fontSize }: SvgIconProps) {
  return (
    <SvgIcon fontSize={fontSize}>
      <svg viewBox="-36 0 392 392.40392" xmlns="http://www.w3.org/2000/svg">
        <path d="m43 355.03125c.492188 3.949219 3.273438 7.230469 7.085938 8.355469 3.816406 1.128906 7.933593-.109375 10.492187-3.152344l68.5-81.507813 72.972656 109.230469c3.058594 4.585938 9.25 5.828125 13.84375 2.777344l65.226563-43.285156c4.589844-3.046875 5.851562-9.234375 2.820312-13.835938l-72.304687-109.675781 101.714843-31.453125c3.800782-1.175781 6.542969-4.488281 6.984376-8.441406.441406-3.953125-1.5-7.792969-4.945313-9.777344l-300.195313-172.929687c-3.289062-1.894532-7.363281-1.765626-10.523437.332031-3.160156 2.097656-4.863281 5.804687-4.394531 9.566406zm242.539062-174.878906-92.539062 28.617187c-3.003906.929688-5.398438 3.21875-6.460938 6.179688-1.058593 2.960937-.664062 6.25 1.066407 8.875l74.152343 112.476562-48.613281 32.261719-74.832031-112.023438c-1.746094-2.617187-4.625-4.257812-7.765625-4.429687-.183594-.011719-.367187-.015625-.550781-.015625-2.953125 0-5.753906 1.308594-7.652344 3.566406l-62.320312 74.152344-37.421876-301.128906zm0 0" />
      </svg>
    </SvgIcon>
  );
}
